<template>
  <v-container
    class="k2-one-column"
    :style="rootContainerStyle"
  >
    <v-row justify="center">
      <v-col
        class="k2-column-primary"
        :cols="12"
      >
        <v-container fluid>
          <!-- @slot The content to place in the column -->
          <slot />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed } from 'vue'
import { DisplayBreakpoint, useDisplay } from 'vuetify'

const MAX_WIDTHS: Record<DisplayBreakpoint, string> = {
  xxl: '624px',
  xl: '624px',
  lg: '624px',
  md: '624px',
  sm: '624px',
  xs: '100%'
}

export default {
  name: 'KomodoOneColumn'
}
</script>

<script setup lang="ts">
const { name } = useDisplay()
const rootContainerStyle = computed((): Record<string, string | number> => {
  return {
    maxWidth: MAX_WIDTHS[name.value]
  }
})
</script>

<style lang="scss" scoped>
@import '../scss/templates';

.k2-one-column {
  @include template-column-spacings;
}
</style>
